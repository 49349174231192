import { Slot } from "@radix-ui/react-slot";
import type React from "react";
import { type HTMLAttributes, forwardRef } from "react";
import { tv } from "tailwind-variants";

import { tm } from "./primitive";

const listItemStyles = tv({
  base: ["flex items-center h-10 outline-none", "hover:bg-muted/50 "],
  variants: {
    noBorder: {
      true: "border-b-0 hover:bg-inherit",
      false: "border-b [&:last-child]:border-b-0",
    },
  },
});

export type ListItemProps =
  | {
      asChild: true;
      children: React.ReactNode;
      className?: string;
      noBorder?: boolean;
    }
  | ({ asChild?: false; noBorder?: boolean } & React.ComponentProps<"div">);

const ListItem = forwardRef<HTMLDivElement, ListItemProps>(
  ({ asChild, children, className, noBorder, ...props }, ref) => {
    const Comp = asChild ? Slot : "div";
    return (
      <Comp
        {...props}
        ref={ref}
        className={tm(listItemStyles({ noBorder }), className)}
      >
        {children}
      </Comp>
    );
  },
);

type ListItemSectionProps = HTMLAttributes<HTMLDivElement>;

const ListItemLeft = forwardRef<HTMLDivElement, ListItemSectionProps>(
  (props, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={tm(
          ["flex items-center flex-1 px-3 gap-2 min-w-0"],
          props.className,
        )}
      />
    );
  },
);

const ListItemRight = forwardRef<HTMLDivElement, ListItemSectionProps>(
  (props, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={tm("flex items-center shrink-0 px-3 gap-2", props.className)}
      />
    );
  },
);

const ListItemActions = forwardRef<HTMLDivElement, ListItemSectionProps>(
  (props, ref) => {
    return (
      <div
        {...props}
        ref={ref}
        className={tm(
          ["flex items-center justify-end px-3 border-l gap-2 h-full"],
          props.className,
        )}
      />
    );
  },
);

const Namespace = Object.assign(ListItem, {
  Left: ListItemLeft,
  Right: ListItemRight,
  Actions: ListItemActions,
});

export { Namespace as ListItem };
