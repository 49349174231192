import { Command as CommandPrimitive } from "cmdk";
import { SearchIcon } from "lucide-react";
import * as React from "react";
import type { ModalOverlayProps } from "react-aria-components";

import { cn } from "../utils";
import { Modal } from "./modal";
import { useMediaQuery } from "./primitive";

interface CommandMenuContextProps {
  hideSearchIndicator?: boolean;
  hideCloseButton?: boolean;
  messageOnEmpty?: boolean | string;
}

interface CommandContextProps {
  isMobile?: boolean;
}

const CommandMenuContext = React.createContext<CommandMenuContextProps>({});
const CommandContext = React.createContext<CommandContextProps>({});

export const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, children, ...props }, ref) => {
  const isMobile = useMediaQuery("(max-width: 600px)");
  return (
    <CommandPrimitive
      ref={ref}
      className={cn(
        "flex h-full w-full flex-col overflow-hidden rounded-lg bg-popover text-popover-foreground",
        className,
      )}
      {...props}
    >
      <CommandContext.Provider value={{ isMobile }}>
        {children}
      </CommandContext.Provider>
    </CommandPrimitive>
  );
});
Command.displayName = CommandPrimitive.displayName;

interface CommandDialogProps
  extends ModalOverlayProps,
    CommandMenuContextProps {
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  commandRef?: React.RefObject<React.ElementRef<typeof CommandPrimitive>>;
  shouldFilter?: boolean;
  children: React.ReactNode;
  animate?: boolean;
}

const CommandMenu = ({
  hideSearchIndicator = false,
  hideCloseButton = false,
  messageOnEmpty = false,
  onKeyDown,
  commandRef,
  children,
  animate = true,
  shouldFilter = true,
  ...props
}: CommandDialogProps) => {
  return (
    <CommandMenuContext.Provider
      value={{ hideSearchIndicator, hideCloseButton, messageOnEmpty }}
    >
      {/* <Modal
        {...props}
        // modalRef={commandRef}
        // animate={animate}
        // required for escape to go back a page
        // isKeyboardDismissDisabled={true}
      > */}
      <Modal.Content
        // className="outline-none"
        modalRef={commandRef}
        style={{
          transition: "ease-in-out 0.1s",
        }}
        classNames={{
          content: "p-0",
        }}
        aria-label="Command Palette"
        isOpen={props.isOpen}
        onOpenChange={props.onOpenChange}
        isKeyboardDismissDisabled={true}
      >
        <Modal.Body className="!p-0">
          <Command
            className="[&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:pt-0 [&_[cmdk-group]]:px-2 [&_[cmdk-input-wrapper]_svg]:h-4 [&_[cmdk-input-wrapper]_svg]:w-4 [&_[cmdk-input]]:h-12 [&_[cmdk-item]]:px-2 [&_[cmdk-item]]:py-3 [&_[cmdk-item]_svg]:size-4 [&_[cmdk-item]_svg]:mr-2"
            onKeyDown={onKeyDown}
            shouldFilter={shouldFilter}
          >
            {children}
          </Command>
          {/* {!hideCloseButton && (
                  <Button
                    autoFocus={!isDesktop}
                    onPress={close}
                    className={closeButton()}
                  >
                    <span className="lg:block hidden">Esc</span>
                    <span className="lg:hidden -mr-2 block">
                      <IconX />
                      <span className="sr-only">Close command palette</span>
                    </span>
                  </Button>
                )} */}
        </Modal.Body>
      </Modal.Content>
      {/* </Modal> */}
    </CommandMenuContext.Provider>
  );
};

export interface CommandInputProps
  extends React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input> {
  icon?: React.ReactNode;
}

export const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  CommandInputProps
>(({ className, icon, ...props }, ref) => {
  return (
    <div className="flex border-b items-center px-3">
      {icon ? icon : <SearchIcon className="mr-2 size-4 shrink-0 opacity-50" />}
      <CommandPrimitive.Input
        autoFocus={props.autoFocus ?? true}
        ref={ref}
        className={cn(
          "flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        {...props}
      />
    </div>
  );
});

CommandInput.displayName = CommandPrimitive.Input.displayName;

interface CommandListProps
  extends React.ComponentProps<typeof CommandPrimitive.List> {}

export const CommandList = ({ className, ...props }: CommandListProps) => {
  const { messageOnEmpty = false } = React.useContext(CommandMenuContext);
  return (
    <CommandPrimitive.List
      className={cn(
        "max-h-[300px] overflow-y-auto overflow-x-hidden",
        className,
      )}
      {...props}
    >
      {messageOnEmpty !== false && (
        <CommandEmpty>
          {typeof messageOnEmpty === "string"
            ? messageOnEmpty
            : "No results found."}
        </CommandEmpty>
      )}
      {props.children}
    </CommandPrimitive.List>
  );
};

interface CommandEmptyProps
  extends React.ComponentProps<typeof CommandPrimitive.Empty> {}

export const CommandEmpty = ({ className, ...props }: CommandEmptyProps) => {
  return (
    <CommandPrimitive.Empty
      className={cn(
        "py-6 text-center text-sm text-muted-foreground/80",
        className,
      )}
      {...props}
    />
  );
};

interface CommandGroupProps
  extends React.ComponentProps<typeof CommandPrimitive.Group> {
  separator?: boolean;
}

export const CommandGroup = ({
  className,
  separator,
  ...props
}: CommandGroupProps) => {
  return (
    <>
      <CommandPrimitive.Group
        className={cn(
          "overflow-hidden p-1 text-foreground [&_[cmdk-group-heading]]:px-2 [&_[cmdk-group-heading]]:py-1.5 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-medium [&_[cmdk-group-heading]]:text-muted-foreground",
          className,
        )}
        {...props}
      >
        {props.children}
        {separator && <CommandSeparator className="mt-2" />}
      </CommandPrimitive.Group>
    </>
  );
};

export const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn("-mx-1 h-px bg-border", className)}
    {...props}
  />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

export interface CommandItemProps
  extends React.ComponentProps<typeof CommandPrimitive.Item> {
  isDanger?: boolean;
}

export const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  CommandItemProps
>(({ isDanger, className, ...props }, ref) => {
  return (
    <CommandPrimitive.Item
      ref={ref}
      data-danger={isDanger ? "true" : undefined}
      className={cn(
        "relative flex cursor-default select-none items-center rounded-md px-2 py-1.5 text-sm outline-none aria-selected:bg-accent aria-selected:text-accent-foreground aria-disabled:pointer-events-none aria-disabled:opacity-50",
        className,
      )}
      {...props}
    />
  );
});

export const CommandShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn(
        "ml-auto text-xs tracking-widest text-muted-foreground font-mono",
        className,
      )}
      {...props}
    />
  );
};
CommandShortcut.displayName = "CommandShortcut";

CommandMenu.Command = Command;
CommandMenu.Empty = CommandEmpty;
CommandMenu.Input = CommandInput;
CommandMenu.Item = CommandItem;
// CommandMenu.Keyboard = CommandMenuKeyboard;
CommandMenu.List = CommandList;
CommandMenu.Section = CommandGroup;
CommandMenu.Separator = CommandSeparator;
CommandMenu.Shortcut = CommandShortcut;
// CommandMenu.Description = CommandMenuDescription;

export { CommandMenu };
export { useCommandState } from "cmdk";
